import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { decimalToFraction } from '../utils/helper';
import Layout from "../components/layout";
import SEO from "../components/seo";

function normalizePhotoData(images) {
  let photos = []
  // eslint-disable-next-line
  images.map(({ fluid }) => {
    const fraction = decimalToFraction(fluid.aspectRatio);
    photos.push({
      "src": fluid.src,
      "srcSet": fluid.srcSet,
      "width": Number(fraction[0]),
      "height": Number(fraction[1])
    });
  })
  return photos;
}

function PortfolioTemplate(props) {
  const portfolio = props.data.contentfulPortfolio;
  const siteTitle = props.data.site.siteMetadata.title;
  const { previous, next } = props.pageContext;
  const photos = normalizePhotoData(portfolio.images)

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (event, obj) => {
    setCurrentImage(obj.index);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Layout location={`/${portfolio.title}`} title={siteTitle}>
      <SEO
        title={portfolio.title}
        description={portfolio.description.description}
      />
      <div id="portfolio">
        <div>
          <h1>{portfolio.title}</h1>
          <p>{portfolio.description.description}</p>
        </div>
        <div>
          <Gallery photos={photos} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
        <ul
          style={{
            width: `100%`,
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0
          }}
        >
          <li>
            {previous && (
              <Link to={previous.title} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.title} rel="next">
                {next.title} →
                </Link>
            )}
          </li>
        </ul>
      </div>
    </Layout>
  );
}

export default PortfolioTemplate;

export const pageQuery = graphql`
  query PortfolioByTitle($title: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPortfolio(title: { eq: $title }) {
      title
      description {
        description
      }
      images {
        fluid {
          src
          aspectRatio
          srcSet
        }
      }
    }
  }
`;
